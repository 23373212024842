import {
	ComponentRef,
	Directive,
	EventEmitter,
	Injector,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewContainerRef,
} from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { loadModuleAsync } from '../../remote-app-loader';

export interface IGlobalTrashContainerComponent {
	onCloseButtonClicked: EventEmitter<void>;
}

@Directive({
	standalone: true,
	selector: '[globalTrashContainerComponentLoaderDirective]',
	exportAs: 'GlobalTrashLoaderDirective',
})
export class GlobalTrashLoaderDirective
	implements OnInit, OnChanges, OnDestroy, IGlobalTrashContainerComponent
{
	@Output() onCloseButtonClicked = new EventEmitter<void>();

	private _componentRef: ComponentRef<IGlobalTrashContainerComponent>;
	private _destroy$ = new Subject<void>();

	constructor(
		private _injector: Injector,
		private _viewRef: ViewContainerRef
	) {}

	ngOnInit(): void {
		void this.initComponent();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes &&
			Object.keys(changes).length > 0 &&
			Object.keys(changes).every(key => !changes[key].firstChange)
		) {
			this.setComponentInputs();
		}
	}

	ngOnDestroy(): void {
		this._destroy$.next();
	}

	private async initComponent() {
		const component = await loadModuleAsync(
			'adminConsole',
			'GlobalTrashRemoteComponent'
		);
		this._componentRef = this._viewRef.createComponent<IGlobalTrashContainerComponent>(
			component,
			{ injector: this._injector }
		);

		this.setComponentInputs();
		// Subscribe output events
		this._componentRef.instance.onCloseButtonClicked
			.pipe(takeUntil(this._destroy$))
			.subscribe(() => {
				this.onCloseButtonClicked.emit();
			});
		this._componentRef.changeDetectorRef.markForCheck();
	}

	private setComponentInputs() {
		if (!this._componentRef) {
			return;
		}
	}
}
