import {
	ComponentRef,
	Directive,
	EventEmitter,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewContainerRef,
	ɵcreateInjector,
} from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { loadModuleAsync } from '../../remote-app-loader';

@Directive({ selector: '[taskDetailPanelComponentLoader]', standalone: true })
export class TaskDetailPanelLoaderDirective implements OnInit, OnChanges, OnDestroy {
	@Input() embeddedData: any;
	@Input() isSubscribeRoute = false;
	@Input() isClosePopupOutsideClick = true;
	@Input() isShowTaskGroupFiled = false;
	@Input() isFromMyWorkSpace = false;
	@Input() workspaceProjectId: number;
	@Input() isSidePanel = true;
	@Input() showCloseBtn = true;
	@Input() submitterViewVisible = true;
	@Input() showDuplicateAction = true;
	// @Input() showNavMenuPanel = true;
	@Input() useCustomCloseBtn = true;
	@Input() isShowLinkedRecord = false;

	@Output() onHide = new EventEmitter<any>();
	@Output() onTaskDeleted = new EventEmitter<any>();
	@Output() onCloseButtonClicked = new EventEmitter<any>();
	@Output() onLoadTaskInfoFail = new EventEmitter<any>();

	private _destroy$ = new Subject<void>();
	private _componentRef: ComponentRef<any>;

	constructor(
		private _injector: Injector,
		private _viewContainerRef: ViewContainerRef
	) {}

	ngOnInit(): void {
		void this.initComponent();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes &&
			Object.keys(changes).length > 0 &&
			Object.keys(changes).every(key => !changes[key].firstChange)
		) {
			this.setComponentInputs();
		}
	}

	ngOnDestroy(): void {
		this._destroy$.next();
	}

	private async initComponent() {
		await loadModuleAsync('work', 'WorkRemoteTaskDetailPanelModule').then(module => {
			const ProjectKanbanContainerComponent =
				module.components['TaskDetailPanelContainerComponent'];

			const componentRef = this._viewContainerRef.createComponent<any>(
				ProjectKanbanContainerComponent,
				{ injector: ɵcreateInjector(module, this._injector) }
			);
			this._componentRef = componentRef;

			this.setComponentInputs();

			const component = componentRef.instance;
			component.onHide.pipe(takeUntil(this._destroy$)).subscribe((data: never) => {
				this.onHide.emit(data);
			});

			component.onLoadTaskInfoFail
				.pipe(takeUntil(this._destroy$))
				.subscribe((data: never) => {
					this.onLoadTaskInfoFail.emit(data);
				});

			component.onTaskDeleted.pipe(takeUntil(this._destroy$)).subscribe((data: never) => {
				this.onTaskDeleted.emit(data);
			});

			component.onCloseButtonClicked
				.pipe(takeUntil(this._destroy$))
				.subscribe((data: never) => {
					this.onCloseButtonClicked.emit(data);
				});

			componentRef.changeDetectorRef.detectChanges();
		});
	}

	private setComponentInputs() {
		if (!this._componentRef) {
			return;
		}
		this._componentRef.setInput('embeddedData', this.embeddedData);
		this._componentRef.setInput('isSidePanel', this.isSidePanel);
		this._componentRef.setInput('showCloseBtn', this.showCloseBtn);
		this._componentRef.setInput('isSubscribeRoute', this.isSubscribeRoute);
		this._componentRef.setInput('showDuplicateAction', this.showDuplicateAction);
		this._componentRef.setInput('isClosePopupOutsideClick', this.isClosePopupOutsideClick);
		this._componentRef.setInput('isShowTaskGroupFiled', this.isShowTaskGroupFiled);
		this._componentRef.setInput('isShowLinkedRecord', this.isShowLinkedRecord);
		this._componentRef.setInput('isFromMyWorkSpace', this.isFromMyWorkSpace);
		this._componentRef.setInput('workspaceProjectId', this.workspaceProjectId);
		this._componentRef.setInput('submitterViewVisible', this.submitterViewVisible);
		// this._componentRef.setInput('showNavMenuPanel', this.showNavMenuPanel);
		this._componentRef.setInput('useCustomCloseBtn', this.useCustomCloseBtn);
	}
}
