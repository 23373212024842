import {
	ComponentRef,
	Directive,
	EventEmitter,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewContainerRef,
} from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { loadModuleAsync } from '../../remote-app-loader';

export interface ICustomFormRecordDetailContainerComponent {
	detectClickOutsideAnElement: boolean;
	showBackButton: boolean;
	showCloseButton: boolean;
	showClientInfo: boolean;
	showTitle: boolean;
	layoutType: string;
	showSubmitterView: boolean;
	showRecordHeader: boolean;
	showDuplicateAction: boolean;

	customTitle: string;
	formResult: unknown;
	taskId: number;
	projectId: number;
	visible: boolean;
	disableScroll: boolean;
	embeddedComment: any;
	totalRecords: number;
	selectedTabType: any;
	index: number;
	projectMembers: any;

	visibleChange: EventEmitter<boolean>;
	onBacked: EventEmitter<any>;
	onClosed: EventEmitter<void>;
	onDeleted: EventEmitter<number>;
	onIndexChanged: EventEmitter<number>;

	onComponentLoaded?: EventEmitter<boolean>;
	updateFormResult: () => void;
}

@Directive({
	standalone: true,
	selector: '[customAppRecordDetailLoaderDirective]',
	exportAs: 'RecordDetailLoaderDirective',
})
export class CustomAppRecordDetailLoaderDirective
	implements OnInit, OnChanges, OnDestroy, ICustomFormRecordDetailContainerComponent
{
	@Input() taskId: number;
	@Input() projectId: number;
	@Input() visible = true;
	@Input() showSubmitterView = false;
	@Input() formResult: unknown;
	@Input() isSubscribeRoute = true;
	@Input() showCloseButton = true;
	@Input() showToggleSectionButton = true;
	@Input() disableScroll = false;
	@Input() layoutType: string;
	@Input() showPreNextButton = true;
	@Input() showRecordHeader = true;
	@Input() showClientInfo: boolean;
	@Input() showTitle: boolean;
	@Input() customTitle: string;
	@Input() detectClickOutsideAnElement = true;
	@Input() showBackButton = false;
	@Input() showDuplicateAction = false;
	@Input() resizeFollowTableField = false;

	@Input() embeddedComment: any;
	@Input() totalRecords: number;
	@Input() selectedTabType: any;
	@Input() index: number;
	@Input() projectMembers: any;
	@Input() defaultLayout: string;
	@Input() appId: number;

	@Output() visibleChange = new EventEmitter();
	@Output() onBacked = new EventEmitter<void>();
	@Output() onClosed = new EventEmitter<void>();
	@Output() onDeleted = new EventEmitter<number>();
	@Output() onIndexChanged = new EventEmitter<number>();
	@Output() onComponentLoaded = new EventEmitter<boolean>();

	private _componentRef: ComponentRef<any>;
	private _destroy$ = new Subject<void>();

	constructor(
		private _injector: Injector,
		private _viewRef: ViewContainerRef
	) {}

	ngOnInit(): void {
		void this.initComponent();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes &&
			Object.keys(changes).length > 0 &&
			Object.keys(changes).every(key => !changes[key].firstChange)
		) {
			this.setComponentInputs();
		}
	}

	ngOnDestroy(): void {
		this._destroy$.next();
	}

	public updateFormResult() {
		this._componentRef.instance.updateFormResult();
	}

	private async initComponent() {
		const component = await loadModuleAsync('customApp', 'RecordDetailLayoutRemoteModule');

		this._componentRef =
			this._viewRef.createComponent<ICustomFormRecordDetailContainerComponent>(component, {
				injector: this._injector,
			});

		this.setComponentInputs();
		// Subscribe close event;
		this._componentRef.instance.visibleChange
			.pipe(takeUntil(this._destroy$))
			.subscribe((visible: boolean) => {
				this.visible = visible;
				this.visibleChange.emit(visible);
			});

		this._componentRef.instance.onClosed
			.pipe(takeUntil(this._destroy$))
			.subscribe(() => this.onClosed.emit());

		this._componentRef.instance.onBacked
			.pipe(takeUntil(this._destroy$))
			.subscribe(() => this.onBacked.emit());

		this._componentRef.instance.onDeleted
			.pipe(takeUntil(this._destroy$))
			.subscribe(() => this.onDeleted.emit());

		this._componentRef.instance.onIndexChanged
			.pipe(takeUntil(this._destroy$))
			.subscribe(() => this.onIndexChanged.emit());

		this._componentRef.changeDetectorRef.markForCheck();

		this.onComponentLoaded.emit(true);
	}

	private setComponentInputs() {
		if (!this._componentRef) {
			return;
		}
		// Set input data
		this._componentRef.setInput('taskId', this.taskId);
		this._componentRef.setInput('isSubscribeRoute', this.isSubscribeRoute);
		this._componentRef.setInput('projectId', this.projectId);
		this._componentRef.setInput('visible', this.visible);
		this._componentRef.setInput('showSubmitterView', this.showSubmitterView);
		this._componentRef.setInput('showCloseButton', this.showCloseButton);
		this._componentRef.setInput('showToggleSectionButton', this.showToggleSectionButton);
		this._componentRef.setInput('showRecordHeader', this.showRecordHeader);
		this._componentRef.setInput('layoutType', this.layoutType);
		this._componentRef.setInput('showPreNextButton', this.showPreNextButton);
		this._componentRef.setInput('showClientInfo', this.showClientInfo);
		this._componentRef.setInput('showTitle', this.showTitle);
		this._componentRef.setInput('customTitle', this.customTitle);
		this._componentRef.setInput('disableScroll', this.disableScroll);
		this._componentRef.setInput('formResult', this.formResult);
		this._componentRef.setInput('embeddedComment', this.embeddedComment);
		this._componentRef.setInput('totalRecords', this.totalRecords);
		this._componentRef.setInput('selectedTabType', this.selectedTabType);
		this._componentRef.setInput('index', this.index);
		this._componentRef.setInput(
			'detectClickOutsideAnElement',
			this.detectClickOutsideAnElement
		);
		this._componentRef.setInput('showBackButton', this.showBackButton);
		this._componentRef.setInput('projectMembers', this.projectMembers);
		this._componentRef.setInput('resizeFollowTableField', this.resizeFollowTableField);
		this._componentRef.setInput('defaultLayout', this.defaultLayout);
		this._componentRef.setInput('appId', this.appId);
	}

	getFormResult(skipLoading = false) {
		this._componentRef.instance?.getFormResult(skipLoading);
	}
}
