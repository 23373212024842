import {
	ComponentRef,
	Directive,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewContainerRef,
	ɵcreateInjector,
} from '@angular/core';

import { Subject } from 'rxjs';

import { loadModuleAsync } from '../../remote-app-loader';

@Directive({ selector: '[projectSharingSettingComponentLoader]', standalone: true })
export class ProjectSharingSettingLoaderDirective implements OnInit, OnChanges, OnDestroy {
	@Input() currentUserId: number;
	@Input() canViewMember = true;
	@Input() canInviteMember = true;
	@Input() canEditRoleMember = true;
	@Input() canDeleteMember = true;
	@Input() canApproveMember = true;
	@Input() showRoleScope = true;
	@Input() textContentData: any;
	@Input() allowInviteUserNotBelongTenant = true;
	@Input() showReloadUserRolePopup = true;

	private _componentRef: ComponentRef<any>;

	private _destroy$ = new Subject<void>();

	constructor(
		private _injector: Injector,
		private _viewContainerRef: ViewContainerRef
	) {}

	ngOnInit(): void {
		void this.initComponent();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes &&
			Object.keys(changes).length > 0 &&
			Object.keys(changes).every(key => !changes[key].firstChange)
		) {
			this.setComponentInputs();
		}
	}

	ngOnDestroy(): void {
		this._destroy$.next();
	}

	private async initComponent() {
		await loadModuleAsync('work', 'WorkRemoteProjectSharingSettingModule').then(module => {
			const ProjectSharingSettingContainerComponent =
				module.components['ProjectSharingSettingContainerComponent'];

			this._componentRef = this._viewContainerRef.createComponent<any>(
				ProjectSharingSettingContainerComponent,
				{ injector: ɵcreateInjector(module, this._injector) }
			);

			this.setComponentInputs();

			this._componentRef.changeDetectorRef.markForCheck();
		});
	}

	private setComponentInputs() {
		if (!this._componentRef) {
			return;
		}

		this._componentRef.setInput('currentUserId', this.currentUserId);
		this._componentRef.setInput('canViewMember', this.canViewMember);
		this._componentRef.setInput('canInviteMember', this.canInviteMember);
		this._componentRef.setInput('canEditRoleMember', this.canEditRoleMember);
		this._componentRef.setInput('canDeleteMember', this.canDeleteMember);
		this._componentRef.setInput('canApproveMember', this.canApproveMember);
		this._componentRef.setInput('showRoleScope', this.showRoleScope);
		this._componentRef.setInput('textContentData', this.textContentData);
		this._componentRef.setInput(
			'allowInviteUserNotBelongTenant',
			this.allowInviteUserNotBelongTenant
		);
		this._componentRef.setInput('showReloadUserRolePopup', this.showReloadUserRolePopup);
	}
}
